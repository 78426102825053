import { Box, Space } from "@gocardless/flux-react";
import { useMandateList } from "@gocardless/api/dashboard/mandate";
import { useSchemeIdentifierList } from "@gocardless/api/dashboard/scheme-identifier";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import {
  CreateSubscriptionFormComponent,
  MandateList,
} from "./CreateSubscriptionForm";

import { Tip } from "src/components/ui/Tip/Tip";
import { useOrganisation } from "src/queries/organisation";
import { useIsRestricted } from "src/common/hooks/useIsRestricted";
import { useVerificationStatus } from "src/common/hooks/useVerificationStatus";
import { VerifyYourAccountError } from "src/components/routes/Customers/CustomerDetail/common/VerifyYourAccountError";

export const CreateNewSubscription: React.FC<{
  customerId: string;
}> = ({ customerId }) => {
  const organisation = useOrganisation();
  const { data: mandatesResponse } = useMandateList({
    customer: customerId,
    status: "active,submitted,pending_submission,pending_customer_approval",
  });
  const { data: schemeIdentifierListResponse } = useSchemeIdentifierList({
    organisation: organisation?.id,
  });
  const { i18n } = useLingui();

  const { isRestricted: isSubscriptionCreateRestricted } = useIsRestricted({
    subscriptions: "create",
  });

  const { collectionsPermitted } = useVerificationStatus();

  return (
    <Box>
      {!collectionsPermitted ? (
        <>
          <Space v={2} />
          <VerifyYourAccountError
            header={
              <Trans id="existing-customer-create.verify-banner.header">
                Please verify to create a subscription
              </Trans>
            }
            subtitle={
              <Trans id="existing-customer-create.verify-banner.description">
                Before you can schedule a subscription payment for this customer
                you’ll need to verify your account.
              </Trans>
            }
          />
        </>
      ) : (
        <Tip
          header={i18n._(
            t({
              id: "existing-customer-create.tip.header",
              message: "Create and add your customer to a new Subscription",
            })
          )}
          details={i18n._(
            t({
              message:
                "Create a unique individual Subscription for this customer and get paid automatically with no hassle. You can also save this Subscription as a template for future by ticking the box at the bottom.",
            })
          )}
          showTipTag
        />
      )}
      <Space v={1.5} />
      {mandatesResponse?.mandates?.length ? (
        <CreateSubscriptionFormComponent
          mandateList={mandatesResponse?.mandates as MandateList}
          schemeIdentifiers={
            schemeIdentifierListResponse?.scheme_identifiers || []
          }
          isSubscriptionCreateRestricted={isSubscriptionCreateRestricted}
        />
      ) : null}
    </Box>
  );
};
