import {
  Box,
  Button,
  ButtonLayout,
  ButtonVariant,
  Checkbox,
  ColorPreset,
  Field,
  FontWeight,
  Form,
  Glyph,
  Icon,
  Label,
  Legend,
  P,
  PlainButton,
  Space,
  Text,
  TextAlign,
  TypePreset,
  Visibility,
} from "@gocardless/flux-react";
import { useState } from "react";
import {
  DayOfWeek,
  IntervalUnit,
  MandateResource,
  Month,
  SchemeIdentifier,
  SubscriptionCreateRequestBody,
  PlanCreateRequestBody,
  PlanSubscribeRequestBody,
  PlanResource,
} from "@gocardless/api/dashboard/types";
import { FormProvider, useForm } from "react-hook-form";
import { planCreate, planSubscribe } from "@gocardless/api/dashboard/plan";
import {
  useSubscriptionCreate,
  SubscriptionCreateResponseBody,
} from "@gocardless/api/dashboard/subscription";
import { t, Trans } from "@lingui/macro";
import _, { isArray } from "lodash";
import { Errors } from "@gocardless/api/utils/error";
import { HTTPError } from "@gocardless/api/utils/api";
import { useRouter } from "next/router";
import {
  parseDate,
  CalendarDate,
  today,
  getLocalTimeZone,
} from "@internationalized/date";
import { i18n } from "@lingui/core";

import { RedirectURLField } from "./FormFields/RedirectURLField";
import { ExistingCustomerCreateInstructions } from "./ExistingCustomerCreateInstructions";

import { Currency } from "src/common/currencies";
import { AllScheme, schemeToCurrency } from "src/common/scheme";
import { RestrictedDatePicker } from "src/components/ui/RestrictedDatePicker/RestrictedDatePicker";
import { useToastNotification } from "src/hooks/useToastNotification";
import {
  PaymentType,
  PaymentsRequireApprovalNotice,
} from "src/components/ui/PaymentsRequireApprovalNotice/PaymentsRequireApprovalNotice";
import { getAmountTypeError } from "src/components/routes/RequestPayment/common/helpers";
import { useDefaultCreditor } from "src/queries/creditor";
import { captureException } from "src/technical-integrations/sentry/sentry";
import { getRouteURL, Route } from "src/common/routing";
import { MobilePreview } from "src/components/routes/RequestPayment/common/components/TwoPanelLayout/TwoPanelLayout";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { castDateToString, castStringToDate } from "src/common/date-helper";
import { EstimatedPaymentFee } from "src/components/ui/EstimatedPaymentFee/EstimatedPaymentFee";
import {
  CustomField,
  CustomMetadataFormField,
} from "src/components/ui/CustomMetadataField/CustomMetadataField";
import { PaymentReferenceField } from "src/components/ui/PaymentReferenceField/PaymentReferenceField";
import { getCleanedMetadata } from "src/components/ui/CustomMetadataField/helpers";
import { AmountField } from "src/components/form/AmountField";
import { IntervalUnitField } from "src/components/form/IntervalUnitField";
import { CustomIntervalField } from "src/components/form/CustomIntervalField";
import { NumberOfPaymentsField } from "src/components/routes/RequestPayment/RecurringPayment/ExistingCustomerCreate/FormFields/NumberOfPaymentsField";
import { MandateField } from "src/components/form/MandateField";
import { NameField } from "src/components/form/NameField";
import { DayOfMonthField } from "src/components/routes/RequestPayment/RecurringPayment/ExistingCustomerCreate/FormFields/DayOfMonthField";
import { useCollectionsPermitted } from "src/components/routes/SetupPayments/common/hooks/useCollectionsPermitted";
import { getErrorNotification } from "src/hooks/useHandleFormError";
import { useIdempotencyKeyHeader } from "src/hooks/useIdempotencyKeyHeader";
import { TakePaymentAsSoonAsPossibleField } from "src/components/form/TakePaymentAsSoonAsPossibleField";
import { getLastDayOfMonth } from "src/utils/date-parser";

const CUSTOM_INTERVAL = "Custom";

const MONTH_COUNT = 14;
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface MonthYear {
  id: string;
  month: string;
  year: number;
}

const generateMonthYearOptions = (): MonthYear[] => {
  const monthYear = [];
  let year = new Date().getFullYear();
  let month = new Date().getMonth();
  for (let i = 0; i < MONTH_COUNT; i++) {
    monthYear.push({
      id: (MONTHS[month] || "") + year,
      month: MONTHS[month] || "",
      year: year,
    });
    month = month + 1;
    if (month === 12) {
      year = year + 1;
      month = 0;
    }
  }
  return monthYear;
};

export type MandateList = [MandateResource, ...MandateResource[]];

interface CreateSubscriptionForm {
  name: string;
  amount: number;
  currency: Currency;
  interval_unit: IntervalUnit | typeof CUSTOM_INTERVAL;
  month?: string;
  interval?: number;
  day_of_week?: DayOfWeek;
  day_of_month?: number;
  count?: number;
  timeframe?: string;
  custom_interval_unit?: IntervalUnit | undefined;
  mandate: string;
  metadata: CustomField[];
  save_as_subscription_template: boolean;
  redirect_url?: string;
  takePaymentAsSoonAsPossible: "true" | "false";
  payment_reference?: string;
}

interface CreateSubscriptionFormProps {
  schemeIdentifiers: SchemeIdentifier[];
  mandateList: MandateList;
  isSubscriptionCreateRestricted: boolean;
}

export const CreateSubscriptionFormComponent: React.FC<
  CreateSubscriptionFormProps
> = ({ schemeIdentifiers, mandateList, isSubscriptionCreateRestricted }) => {
  const creditor = useDefaultCreditor();
  const { triggerErrorNotification } = useToastNotification();
  const { collectionsEnabledTrackingAttribute } = useCollectionsPermitted();

  const router = useRouter();
  const { sendEventPromiseWithTimeout } = useSegment();
  const { source, customer_id: customerId } = router.query;

  const idempotencyKeyHeader = useIdempotencyKeyHeader();
  const monthYear = generateMonthYearOptions();

  const methods = useForm<CreateSubscriptionForm>({
    defaultValues: {
      metadata: [{ name: "", value: "" }],
      currency: Currency.Gbp,
      name: "",
      interval_unit: IntervalUnit.Monthly,
      mandate: mandateList.length ? mandateList[0]?.id : "",
      save_as_subscription_template: false,
      redirect_url: "",
      month: generateMonthYearOptions()[0]?.id,
      takePaymentAsSoonAsPossible: "true",
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
  } = methods;
  const interval_unit = watch("interval_unit");
  const custom_interval_unit = watch("custom_interval_unit");
  const amount = watch("amount");
  const month = watch("month");
  const watchDayOfMonth = watch("day_of_month");
  const watchMandateId = watch("mandate");
  const watchSaveAsSubscriptionTemplate = watch(
    "save_as_subscription_template"
  );

  const [showAdvanced, setShowAdvanced] = useState(false);
  const [invalidDateChosen, setInvalidDateChosen] = useState<boolean>(false);

  const watchtakePaymentAsSoonAsPossible = watch("takePaymentAsSoonAsPossible");
  const selectedMandate: MandateResource =
    mandateList.find((mandate) => mandate.id === watchMandateId) ||
    mandateList[0];
  const defaultChargeDate = selectedMandate.next_possible_charge_date
    ? parseDate(castDateToString(selectedMandate.next_possible_charge_date))
    : today(getLocalTimeZone());

  const firstAvailableDate = selectedMandate.next_possible_charge_date
    ? castDateToString(selectedMandate.next_possible_charge_date)
    : today(getLocalTimeZone()).toString();

  const minimumChargeDate = defaultChargeDate;
  const [chargeDate, setChargeDate] = useState<CalendarDate | undefined>(
    minimumChargeDate
  );

  const submissionIsDisabled =
    Object.keys(errors).length > 0 ||
    (watchtakePaymentAsSoonAsPossible === "false" && invalidDateChosen);

  const navigateToCompletionPage = ({
    createdTemplate,
    planId,
    subscriptionId,
  }: {
    createdTemplate: boolean;
    planId?: string;
    subscriptionId?: string;
  }) => {
    const queryParams: {
      source?: string;
      customer_id?: string;
      plan_id?: string;
      subscription_id?: string;
      created_template?: string;
    } = {};
    if (source !== undefined) {
      queryParams["source"] = source as string;
    }
    if (customerId !== undefined) {
      queryParams["customer_id"] = customerId as string;
    }
    if (planId !== undefined) {
      queryParams["plan_id"] = planId as string;
    }
    if (subscriptionId !== undefined) {
      queryParams["subscription_id"] = subscriptionId as string;
    }
    queryParams["created_template"] = createdTemplate ? "true" : "false";

    router.push(
      getRouteURL({
        route: Route.RecurringPaymentCreateSuccess,
        queryParams: queryParams,
      })
    );
  };

  const getMonth = (): Month | undefined => {
    if (watchtakePaymentAsSoonAsPossible === "true") {
      return;
    }
    let entry;
    if (interval_unit === IntervalUnit.Yearly && month) {
      const monthVal =
        monthYear[monthYear.findIndex((m) => m.id === month)]?.month;
      entry = Object.entries(Month).find(([key, val]) => {
        if (key === monthVal) {
          return val;
        }
        return;
      });
    }

    return entry ? entry[1] : undefined;
  };

  const getApprovalStartDate = (): CalendarDate => {
    const selectedStartDate = getStartDate();
    if (selectedStartDate) {
      return selectedStartDate;
    } else {
      return defaultChargeDate;
    }
  };

  const getStartDate = (): CalendarDate | undefined => {
    let date: CalendarDate | undefined = undefined;
    if (watchtakePaymentAsSoonAsPossible === "true") {
      return;
    }
    if (
      chargeDate &&
      (custom_interval_unit === IntervalUnit.Weekly ||
        interval_unit === IntervalUnit.Weekly)
    ) {
      date = new CalendarDate(
        chargeDate.year,
        chargeDate.month,
        chargeDate.day
      );
    }
    if (month && watchDayOfMonth) {
      const key = monthYear[monthYear.findIndex((m) => m.id === month)];
      if (key) {
        date = new CalendarDate(
          key.year,
          // MONTHS is 0 indexed (obviously), so we need to add 1 to the result
          // here to get the month for the CalendarDate correctly.
          MONTHS.indexOf(key.month || "") + 1,
          watchDayOfMonth > 0
            ? watchDayOfMonth
            : getLastDayOfMonth(
                Number(MONTHS.indexOf(key.month || "")),
                Number(key.year)
              )
        );
      }
    }

    return date;
  };

  const setErrorForFields = (formError: Errors | undefined | "") => {
    const qualifiesForPaymentLimitIncrease = _.get(
      creditor,
      "qualifies_for_payment_limit_increase",
      true
    );

    if (formError) {
      for (const error of formError) {
        if (error.field) {
          if (error.field === "amount") {
            setError(error.field as keyof CreateSubscriptionForm, {
              type: "custom",
              message: getAmountTypeError(
                error,
                qualifiesForPaymentLimitIncrease,
                selectedCurrency
              ),
            });
          } else {
            setError(error.field as keyof CreateSubscriptionForm, {
              type: "custom",
              message: error.message,
            });
          }
        }
      }
    }
  };

  const getIntervalUnit = (): IntervalUnit =>
    interval_unit !== CUSTOM_INTERVAL
      ? interval_unit
      : custom_interval_unit || IntervalUnit.Weekly;

  const handlePlanError = async (error: HTTPError) => {
    const errorPayload = await getErrorNotification(error);
    triggerErrorNotification(errorPayload.notificationPayload);
    setErrorForFields(errorPayload.formError);
    captureException({
      error: error,
    });
  };

  const submitWithTemplate = async (
    planCreateBody: PlanCreateRequestBody,
    planSubscribeBody: PlanSubscribeRequestBody
  ) => {
    try {
      // Create the plan
      const planResponse = await planCreate(
        planCreateBody,
        undefined,
        idempotencyKeyHeader
      );

      const createdPlan: PlanResource | undefined = !isArray(
        planResponse?.plans
      )
        ? planResponse.plans
        : planResponse.plans[0];
      const planId = createdPlan?.id;

      if (!planId) {
        triggerErrorNotification({
          title: "Failed to create Subscription template",
          message: "A subscription has been created for this customer",
        });
        return;
      }

      await sendEventPromiseWithTimeout(TrackingEvent.PLANS_NEW_PLAN_CREATED, {
        plan_id: planId,
        page: router.pathname,
      });

      // Subscribe to the plan
      const subscriptionResponse = await planSubscribe(
        planId,
        planSubscribeBody,
        idempotencyKeyHeader
      );

      await sendEventPromiseWithTimeout(
        TrackingEvent.CUSTOMERS_SUBSCRIPTION_CREATED,
        {
          subscription_id: subscriptionResponse.subscriptions?.id,
          plan_id: planId,
          new_plan_created: true,
          page: router.pathname,
          customer_id: customerId,
          ...collectionsEnabledTrackingAttribute,
        }
      );

      navigateToCompletionPage({
        createdTemplate: true,
        planId,
        subscriptionId: subscriptionResponse.subscriptions?.id,
      });
    } catch (error) {
      await handlePlanError(error as HTTPError);
    }
  };

  const [submitWithoutTemplate] = useSubscriptionCreate({
    onSuccess: async (response: SubscriptionCreateResponseBody | undefined) => {
      await sendEventPromiseWithTimeout(
        TrackingEvent.CUSTOMERS_SUBSCRIPTION_CREATED,
        {
          subscription_id: response?.subscriptions?.id,
          new_plan_created: false,
          page: router.pathname,
          customer_id: customerId,
          ...collectionsEnabledTrackingAttribute,
        }
      );

      navigateToCompletionPage({
        createdTemplate: false,
        subscriptionId: response?.subscriptions?.id,
      });
    },
    onError: handlePlanError,
    headers: idempotencyKeyHeader,
  });

  if (!selectedMandate || !mandateList) {
    return null;
  }

  const selectedCurrency =
    schemeToCurrency[selectedMandate.scheme as AllScheme];

  const cleanSubscriptionSubmitData = (
    data: CreateSubscriptionForm
  ): SubscriptionCreateRequestBody => {
    const { count, day_of_month, interval, name, payment_reference } = data;
    const intervalUnit = getIntervalUnit();
    const requestData: SubscriptionCreateRequestBody = {
      amount: Math.round(amount * 100),
      count,
      currency: selectedCurrency,
      interval_unit: intervalUnit,
      name,
      metadata: getCleanedMetadata(data.metadata),
      links: {
        mandate: data.mandate || mandateList[0]?.id || "",
      },
      start_date: castStringToDate(getStartDate()?.toString()),
      day_of_month:
        intervalUnit === IntervalUnit.Weekly ? undefined : day_of_month,
      month: getMonth(),
      ...(payment_reference
        ? { payment_reference: payment_reference.trim() }
        : {}),
    };
    if (interval_unit === CUSTOM_INTERVAL) {
      requestData.interval = Number(interval);
    }
    return requestData;
  };

  const cleanPlanSubmitData = (
    data: CreateSubscriptionForm
  ): PlanCreateRequestBody => {
    const { count, day_of_month, interval, name, redirect_url } = data;
    const intervalUnit = getIntervalUnit();
    const requestData: PlanCreateRequestBody = {
      amount: Math.round(amount * 100),
      count,
      currency: selectedCurrency,
      interval_unit: intervalUnit,
      name,
      day_of_month:
        intervalUnit === IntervalUnit.Weekly ? undefined : day_of_month,
      month: getMonth(),
      redirect_url: redirect_url ? redirect_url : null,
    };
    if (interval_unit === CUSTOM_INTERVAL) {
      requestData.interval = Number(interval);
    }
    return requestData;
  };

  const submitForm = (data: CreateSubscriptionForm) => {
    if (data.save_as_subscription_template) {
      const planData = cleanPlanSubmitData(data);
      const planSubscribeBody: PlanSubscribeRequestBody = {
        start_date: castStringToDate(getStartDate()?.toString()),
        links: { mandate: data.mandate || mandateList[0]?.id || "" },
      };
      submitWithTemplate(planData, planSubscribeBody);
    } else {
      const dataToSubmit = cleanSubscriptionSubmitData(data);
      submitWithoutTemplate(dataToSubmit);
    }
  };

  return (
    <Box>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit((data) => submitForm(data))}>
          <NameField
            label={i18n._(
              t({
                id: "product-or-subscription-name",
                message: "Product or Subscription name",
              })
            )}
            placeholder="e.g. Subscription 1"
            required={false}
            disabled={isSubscriptionCreateRestricted}
          />
          <Space v={2} />
          <AmountField
            selectedCurrency={selectedCurrency}
            disabled={isSubscriptionCreateRestricted}
          />
          <Box spaceBelow={2} spaceAbove={1} width="100%">
            <EstimatedPaymentFee
              amount={amount}
              currency={selectedCurrency}
              geo={creditor?.geo || "GB"}
            />
          </Box>
          <PaymentReferenceField
            spaceAbove={2}
            spaceBelow={2}
            selectedCurrency={selectedCurrency}
            fieldId="payment_reference"
            isRestricted={isSubscriptionCreateRestricted}
          />
          <IntervalUnitField
            renderLabel={() => <Trans id="frequency">Frequency</Trans>}
            isRestricted={isSubscriptionCreateRestricted}
          />
          <Space v={0.5} />
          {interval_unit === CUSTOM_INTERVAL && (
            <Box spaceAbove={1}>
              <CustomIntervalField />
            </Box>
          )}
          <Space v={1.5} />
          <TakePaymentAsSoonAsPossibleField
            isRestricted={isSubscriptionCreateRestricted}
            firstAvailableDate={firstAvailableDate}
            label={
              <Legend preset={TypePreset.Body_04}>
                <Trans id="add-to-plan.when-to-collect-payment">
                  When do you want to collect these payments?
                </Trans>
              </Legend>
            }
            specificDayLabelDescription={
              <P weight={FontWeight.Normal} preset={TypePreset.Body_01}>
                <Trans>
                  You’ll receive payment on your specified day (future payments
                  will be taken on the same day)
                </Trans>
              </P>
            }
            renderAsapLabelDescription={(date) => (
              <Trans>
                <P
                  weight={FontWeight.Normal}
                  color={ColorPreset.TextOnLight_02}
                  preset={TypePreset.Body_01}
                >
                  Estimated date to receive payment -&nbsp;
                  <b>{date}</b> (future payments will be taken on the same day)
                </P>
              </Trans>
            )}
          />
          {watchtakePaymentAsSoonAsPossible === "false" && minimumChargeDate ? (
            <>
              {(interval_unit === IntervalUnit.Weekly ||
                (interval_unit === CUSTOM_INTERVAL &&
                  custom_interval_unit === IntervalUnit.Weekly)) && (
                <Box layout="flex">
                  <Box width="80%">
                    <Field>
                      <Visibility visible="none">
                        <Label htmlFor="day_of_week">
                          <Trans>Day of Week</Trans>
                        </Label>
                      </Visibility>
                      <RestrictedDatePicker
                        minimumDate={minimumChargeDate}
                        setInvalidDateChosen={setInvalidDateChosen}
                        id="charge_date"
                        ariaLabelledby="chargeDate"
                        defaultValue={minimumChargeDate}
                        onChange={setChargeDate}
                      />
                    </Field>
                  </Box>

                  <Box width="20%" spaceAbove={1.5} spaceBefore={1.5}>
                    <Text
                      textAlign={TextAlign.Justify}
                      preset={TypePreset.Body_01}
                    >
                      <Trans>every week</Trans>
                    </Text>
                  </Box>
                </Box>
              )}
              {(interval_unit === IntervalUnit.Monthly ||
                (interval_unit === CUSTOM_INTERVAL &&
                  custom_interval_unit === IntervalUnit.Monthly) ||
                interval_unit === IntervalUnit.Yearly) && (
                <DayOfMonthField minimumChargeDate={minimumChargeDate} />
              )}
            </>
          ) : null}
          <Space v={1} />
          <NumberOfPaymentsField disabled={isSubscriptionCreateRestricted} />
          {selectedMandate.payments_require_approval && (
            <>
              <Space v={1.5} />
              <PaymentsRequireApprovalNotice
                mandate={selectedMandate}
                startDate={getApprovalStartDate()}
                schemeIdentifiers={schemeIdentifiers}
                paymentType={PaymentType.SUBSCRIPTION}
              />
              <Space v={1.5} />
            </>
          )}
          {watchSaveAsSubscriptionTemplate && (
            <>
              <Space v={1.5} />
              <RedirectURLField />
            </>
          )}
          <Box
            gutterH={2}
            gutterV={2}
            borderColor={ColorPreset.BorderOnLight_03}
            borderWidth={1}
            borderRadius={1}
            spaceAbove={2}
            spaceBelow={1.5}
            bg={ColorPreset.BackgroundLight_03}
          >
            <Box spaceBelow={1} spaceBefore={3}>
              <P
                preset={TypePreset.Heading_03}
                color={ColorPreset.TextOnLight_01}
              >
                <Trans id="create-subscription.save-as-subscription-template">
                  Save as a Subscription template
                </Trans>
              </P>
            </Box>
            <Checkbox
              {...register("save_as_subscription_template")}
              id="save_as_subscription_template"
              disabled={isSubscriptionCreateRestricted}
            >
              <P weight={FontWeight.Light} color={ColorPreset.TextOnLight_02}>
                <Trans id="create-subscription.save-as-subscription-template-description">
                  You’ll be able to share with and add other customers to this
                  Subscription by saving it as a Subscription template, for any
                  future recurring payments.
                </Trans>
              </P>
            </Checkbox>
          </Box>
          {showAdvanced && (
            <>
              <MandateField mandateList={mandateList} />

              {!watchSaveAsSubscriptionTemplate && (
                <>
                  <Space v={2} />
                  <CustomMetadataFormField />
                </>
              )}
            </>
          )}
          <Box spaceAbove={2}>
            <PlainButton
              onClick={() => setShowAdvanced(!showAdvanced)}
              type="button"
            >
              {showAdvanced ? (
                <Trans id="hide-advanced-options">
                  <Text preset={TypePreset.Body_02} weight={FontWeight.Bold}>
                    Hide advanced options{" "}
                    <Icon size="11px" name={Glyph.ChevronUp} />
                  </Text>
                </Trans>
              ) : (
                <Trans id="show-advanced-options">
                  <Text preset={TypePreset.Body_02} weight={FontWeight.Bold}>
                    Show advanced options{" "}
                    <Icon size="11px" name={Glyph.ChevronDown} />
                  </Text>
                </Trans>
              )}
            </PlainButton>
          </Box>
          <MobilePreview rightPanel={<ExistingCustomerCreateInstructions />} />
          <Space v={3} />
          <Button
            variant={ButtonVariant.PrimaryOnLight}
            layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
            type="submit"
            disabled={submissionIsDisabled || isSubscriptionCreateRestricted}
          >
            <Trans id="create-subscription">Create Subscription</Trans>
          </Button>
        </Form>
      </FormProvider>
    </Box>
  );
};
